import { ModalBase } from "../modal_base";
import {AjaxSync} from "../../shared/ajax_utilities";
import {Toast} from "../../shared/toast";

export class SimManagementModal extends ModalBase {
    DOM = {};
    ALLOWED_ACTIONS = ['disable', 'enable', 'topup'];

    constructor() {
        super('sim_management_modal');
    }

    async setupModalView($modalTriggerBtn, additionalData) {
        const opr = $modalTriggerBtn.data('opr');
        if(opr && opr.length > 0) {
            this.MODAL_CONTAINER_EL.find('.operator_name').show().find('.value').text(opr);
        } else {
            this.MODAL_CONTAINER_EL.find('.operator_name').hide();
        }

        const action = $modalTriggerBtn.data('action');
        if(!action || !this.ALLOWED_ACTIONS.includes(action)) {
            console.warn(`Unrecognised action '${action}' for modal sim_management`)
            await this.hide();
            return;
        }

        this.MODAL_CONTAINER_EL.find('.title__content, .modal__content, .modal__btn-primary').hide();
        this.MODAL_CONTAINER_EL.find(`.title__content.${action}-title`).show();
        this.MODAL_CONTAINER_EL.find(`.modal__content.${action}-body`).show();
        this.MODAL_CONTAINER_EL.find(`.modal__btn-primary.${action}-primary`).show();
        this.MODAL_CONTAINER_EL.find(`.modal__content.${action}-body #sim-iccid`).text($modalTriggerBtn.data('iccid'));
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const action = $modalTriggerBtn.data('action');
            if(!action || !this.ALLOWED_ACTIONS.includes(action)) {
                return;
            }

            AjaxSync({
                url: `${app.CACHE.URL_AJAX}sim_management`,
                method: 'POST',
                data: {
                    action: action,
                    iccid: $modalTriggerBtn.data('iccid'),
                }
            }, {
                done: (res) => {
                    if(res.status !== 'success') {
                        Toast.error(this.#getErrorMessage(action));
                        return;
                    }

                    Toast.success(this.#getSuccessMessage(action));
                    this.hide();
                },
                error: (err) => {
                    console.warn(err);
                    Toast.error(this.#getErrorMessage(action));
                }
            });
        });
    }

    #getSuccessMessage(action) {
        switch (action) {
            case 'topup':
                return 'Successfully topped up sim.';
            case 'enable':
                return 'Successfully enabled sim.';
            case 'disable':
                return 'Successfully disabled sim.';
            default:
                return 'Successfully updated sim.';
        }
    }

    #getErrorMessage(action) {
        switch (action) {
            case 'disable':
                return 'Unable to disable sim please try again later.';
            case 'enable':
                return 'Unable to enable sim please try again later.';
            case 'topup':
                return 'Unable to topup sim please try again later.';
            default:
                return 'Unable to update sim please try again later.';
        }
    }
}